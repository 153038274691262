<template>
    <div class="d-inline-block"
        v-if="variant == 'avatar'"
    >
        <VTooltip>
            <Image
                :src="$cdn.url('lor', ['cards', 'square', code], 'png')"
                :size="width"
                :to="link ? `/lor/cards/${code}/${card.name.replace(/[^\w ]+/g,'').replace(/ +/g,'-')}` : null"
                style="border-radius: 50%"
            />
            <template #popper>
                <CardDescription :card="card" :type="type" />
            </template>
        </VTooltip>
    </div>
    <router-link
        :to="link ? `/lor/cards/${code}/${card.name.replace(/[^\w ]+/g,'').replace(/ +/g,'-')}` : null"
        v-else-if="variant == 'banner'"
        :style="{
            width: width,
            height: height,
            display: 'block'
        }"
        :class="[link ? 'cursor-pointer': '', 'stathub-card-banner']"
    >
        <VTooltip>
            <div :style="{
                    zIndex: 1,
                    background: `${gradients[type]}, url('${$cdn.url('lor', ['cards', 'banner', code], 'png')}') no-repeat`,
                    backgroundPosition: 'top right',
                    backgroundSize: 'contain',
                    height: height,
                }"
                class="w-100 text-nowrap d-flex flex-row justify-content-start"
            >
                <small class="d-block font-weight-bold my-auto badge badge-dark ms-2 ps-0" style="padding-right: 2px" v-if="count">x{{count}}</small>
                <small class="d-block font-weight-bold my-auto rounded-circle badge bg-primary ms-2">{{card.cost}}</small>
                <small class="d-block font-weight-bold my-auto ms-2 text-white text-truncate" style="max-width: 50%">{{card.name}}</small>
            </div>
            <template #popper>
                <CardDescription :card="card" :type="type" />
            </template>
        </VTooltip>
    </router-link>
</template>

<style lang="scss">

.stathub-card-banner > div {
    width: 100% !important;
}
</style>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'
import Image from '../Image.vue'
import CardDescription from './CardDescription.vue'

@Options({components: {Image, CardDescription}})
export default class Card extends Vue {

    card: any = {}
    type!: string

    gradients: Record<string, string> = {
        Champion: 'linear-gradient(90deg, rgba(250, 250, 35,0.68) 10%, rgba(250, 250, 35,0.38) 30%, rgba(250, 250, 35,0.05) 88%)',
        Unit: 'linear-gradient(90deg, rgba(76,68,235,0.68) 10%, rgba(76,68,235,0.38) 30%, rgba(76,68,235,0.05) 88%)',
        Spell: 'linear-gradient(90deg, rgba(68,235,228,0.68) 10%, rgba(68,235,228,0.38) 30%, rgba(68,235,228,0.05) 88%)',
        Ability: 'linear-gradient(90deg, rgba(36, 255, 105,0.68) 10%, rgba(36, 255, 105,0.38) 30%, rgba(36, 255, 105,0.05) 88%)',
        Landmark: 'linear-gradient(90deg, rgba(255, 56, 76,0.68) 10%, rgba(255, 56, 76,0.38) 30%, rgba(255, 56, 76,0.05) 88%)',
    }

    @Prop() readonly code!: string
    @Prop() readonly height!: string
    @Prop() readonly width!: string
    @Prop() readonly variant!: string
    @Prop({default: false}) readonly link!: boolean
    @Prop({default: null}) readonly count!: string

    created (): void {
        this.codeChanged(this.code)
    }

    @Watch('code')
    codeChanged(after: string): void {
        this.card = this.$asset.lor.cards[after]
        if (this.card.type == 'Unit' && this.card.supertype == 'Champion')
            this.type = 'Champion'
        else
            this.type = this.card.type
    }

}
</script>
