<template>
    <div :class="[(inline) ? '': 'error-div', 'd-flex', 'flex-column']">
        <div class="mt-auto mx-auto">
            <div><Icon name="info-circle" :size="68" /></div>
            <div></div>
        </div>
        <div class="mx-auto mt-3 mx-5">
            <slot></slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.error-div {
    min-height: 45vh
}

.lds-hourglass {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 32px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-hourglass 1.2s infinite;
}

@keyframes lds-hourglass {
    0% {
        transform: rotate(0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
        transform: rotate(900deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
        transform: rotate(1800deg);
    }
}

</style>

<script lang="ts">
import { Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'
import Icon from './Icon.vue'

@Options({components: {Icon}})
export default class Loading extends Vue {

    @Prop({default: false}) inline!: boolean

}
</script>
