
import { Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

export default class Button extends Vue {

    @Prop({default: null}) readonly to!: string;
    @Prop({default: false}) readonly disabled!: boolean;
    @Prop({default: ['20px', '20px', '20px', '20px']}) readonly borderRadiuses!: Array<string>

}
