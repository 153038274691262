
import { Emit, Model, Prop, Watch } from 'vue-property-decorator'
import { Vue } from 'vue-class-component'

interface Option {
    title: string
    value: any
}

export default class Select extends Vue {

    selectedTitle: string | null = ""
    dropdownToggle = false
    oldRadiuses!: Array<string>
    readyRadiuses!: Array<string>
    IborderRadiuses!: Array<string>

    @Prop({default: ''}) readonly name!: string
    @Prop({default: null}) readonly width!: string | number
    @Prop({default: []}) readonly options!: Array<Option>
    @Prop({default: 1}) readonly zIndex!: string | number
    @Prop({default: ['20px', '20px', '20px', '20px']}) readonly borderRadiuses!: Array<string>

    @Model('modelValue', {default: null})
    readonly value!: any

    @Emit("change")
    valueChangedEmit(): number {
        return this.value
    }

    @Watch("value")
    valueChanged(): void {
        this.$emit("change")
    }

    created (): void {
        this.IborderRadiuses = [...this.borderRadiuses]
        this.oldRadiuses = ['0', '0']
        this.readyRadiuses = ['0', '0']
        for (const o of this.options)
            if (o.value == this.value)
                this.selectedTitle = o.title
    }

    toggle (): void {
        this.dropdownToggle = !this.dropdownToggle
        this.readyRadiuses[0] = this.oldRadiuses[0]
        this.readyRadiuses[1] = this.oldRadiuses[1]
        this.oldRadiuses[0] = this.IborderRadiuses[2]
        this.oldRadiuses[1] = this.IborderRadiuses[3]
        this.IborderRadiuses[2] = this.readyRadiuses[0]
        this.IborderRadiuses[3] = this.readyRadiuses[1]
        setTimeout(() => {
            this.$forceUpdate();
        }, (this.IborderRadiuses[2] + this.IborderRadiuses[3] != '00') ? 200: 0);
    }

}
