import { App } from 'vue'


interface MetaInfo {
    title?: string
    og?: Record<string, string>
    [other: string]: Record<string, string> | string | undefined
}


class MetaUtil {

    install (app: App) : void {
        app.config.globalProperties.$meta = this
    }

    use (o: MetaInfo): void {
        if (o.title) {
            document.title = o.title
        }
        if (o.og){
            if (!Object.keys(o.og).includes('title'))
                o.og.title = o.title || ""
            for (const [name, val] of Object.entries(o.og)) {
                const meta = document.querySelector(`meta[name="og:${name}"]`) || document.createElement('meta');
                if (meta instanceof HTMLMetaElement) {
                    meta.name = 'og:' + name
                    meta.content = val
                    document.getElementsByTagName('head')[0].appendChild(meta);
                }
            }
        }
    }

}


function createMeta (): MetaUtil {
    return new MetaUtil()
}


declare module '@vue/runtime-core' {

    interface ComponentCustomProperties {
        $meta: MetaUtil
    }
}

export { createMeta, MetaUtil }
