import { App } from 'vue'
import moment from 'moment'


class MomentHook {

    install (app: App) : void {
        app.config.globalProperties.$moment = moment
    }

}

function createMoment (): MomentHook {
    return new MomentHook()
}


declare module '@vue/runtime-core' {

    interface ComponentCustomProperties {
        $moment: any
    }
}

export { createMoment, MomentHook }
