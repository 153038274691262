
import { Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'

interface TierStyle {
    string: string
    bgColor: string
}

@Options({})
export default class Tier extends Vue {

    @Prop(Number) readonly value!: number
    @Prop({default: "20px"}) readonly size!: string

    get tier (): TierStyle {
        return this.$asset.lol.tierStyles[this.value]
    }

}
