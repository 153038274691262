<template>
    <button v-if="!to || disabled"
        class="stathub-button"
        :style="{...$css.parse({borderRadiuses})}"
    >
        <slot></slot>
    </button>
    <router-link v-else
        class="stathub-button"
        :style="{...$css.parse({borderRadiuses})}"
        :to="to"
        tag="button"
    >
        <slot></slot>
    </router-link>
</template>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";

.stathub-button {
    @include base-button();
    background: #0005;
    border: 1px solid rgb(88, 220, 238);
    font-size: .9rem;
    border-radius: 20px;
    padding: 8px 16px;
    font-size: 1em;

    &:hover {
        background: radial-gradient(circle at bottom, #e3ffe150 1%, transparent 70%),
            linear-gradient(#0005, #0005)
        // radial-gradient(ellipse at bottom, #ffffff9e, transparent);
    }
}

@include media-breakpoints-down($sm) {
    .stathub-button {
        font-size: .8rem;
        padding: 6px 12px;
    }
}
</style>

<script lang="ts">
import { Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

export default class Button extends Vue {

    @Prop({default: null}) readonly to!: string;
    @Prop({default: false}) readonly disabled!: boolean;
    @Prop({default: ['20px', '20px', '20px', '20px']}) readonly borderRadiuses!: Array<string>

}
</script>
