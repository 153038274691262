import { App } from 'vue'
import axios from 'axios'


class CdnService {

    install (app: App) : void {
        app.config.globalProperties.$cdn = this
    }

    readonly baseUrl = 'https://cdn-stathub.iann838.com'

    url (game: string, path: string | number | Array<string | number>, extension: string): string {
        return `${this.baseUrl}/${game}/${Array.isArray(path) ? path.join("/") : path}${extension ? "." + extension: ""}`
    }

    async get<T> (game: string, path: string | number | Array<string | number>, extension: string): Promise<T> {
        const res = await axios.get(this.url(game, path, extension))
        return res.data
    }

}


function createCdn (): CdnService {
    return new CdnService()
}


declare module '@vue/runtime-core' {

    interface ComponentCustomProperties {
        $cdn: CdnService
    }
}

export { createCdn, CdnService }
