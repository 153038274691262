import { createCdn } from './cdn'
import { createAssetStore } from './asset'
import { createApi } from './api'
import { createStorage } from './stge'

const cdn = createCdn()
const assets = createAssetStore()
const api = createApi()
const stge = createStorage()

export { cdn, assets, api, stge }
