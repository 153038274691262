
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import Nav from '@/components/Nav.vue'
import Loading from '@/components/Loading.vue'
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'

interface Route {
    path: string;
}

@Options({components: {Nav, Loading, Icon, Button}})
export default class App extends Vue {

    refreshing = false
    updateExists = false
    registration: any = null
    policyAccepted: any = null
    interfaces: Array<string> = ['', 'lol', 'lor', 'val']
    routeChanged = false

    @Watch('$route')
    routeChange (after: Route, before: Route): void {
        if (!this.routeChanged) {
            const lastInterface = this.$stge.loc.getItem("lastInterface")
            if (lastInterface && this.$route.path == '/')
                this.$router.push('/' + lastInterface)
            this.routeChanged = true
        }
        let afterGame = after.path.split("/")[1]
        let beforeGame = before.path.split("/")[1]
        if (this.interfaces.includes(afterGame) && afterGame != beforeGame) {
            this.$store.commit("setGame", afterGame)
            this.$stge.loc.setItem("lastInterface", afterGame)
            this.$asset.load(afterGame)
        }
    }

    created (): void {
        document.addEventListener(
            'swUpdated', this.showRefreshUI, { once: true }
        )
        if (localStorage.appCached) {
            navigator.serviceWorker.addEventListener(
                'controllerchange', () => {
                    if (this.refreshing) return
                    this.refreshing = true
                    window.location.reload();
                }
            )
        } else {
            localStorage.appCached = "1"
        }
        this.policyAccepted = localStorage.policyAccepted
    }

    mounted(): void {
        this.$store.commit('setWinWidth', window.innerWidth)
        window.addEventListener('resize', () => {
            this.$store.commit('setWinWidth', window.innerWidth)
        })
    }

    get marginLeft(): string {
        if (this.$store.state.winWidth < 768)
            return '0'
        if (this.$store.state.winWidth < 1300)
            return '68px'
        if (this.$store.state.sideNavExpanded)
            return '238px'
        return '68px'
    }

    showRefreshUI (e: Record<string, any>): void {
        this.registration = e.detail
        this.updateExists = true
    }

    refreshApp (): void {
        this.updateExists = false
        if (!this.registration || !this.registration.waiting) { return; }
        this.registration.waiting.postMessage('skipWaiting')
    }

}
