<template>
    <div class="d-flex" :style="{width}" v-if="orientation == 'horizontal'">
        <Button
            v-for="(o, i) in options"
            :key="i"
            :style="{
                minWidth: (100 / options.length) + '%',
                ...getBorderRadiuses(i)
            }"
            :class="[value == o.value ? 'stathub-tab-active': '', 'text-center', 'w-100', 'px-0']"
            :to="o.to"
            @click="value=o.value"
        >
            <Image class="cursor-pointer" v-if="o.img" :src="o.img" size="25px" />
            {{o.title}}
        </Button>
    </div>
    <div :style="{width}" v-else-if="orientation == 'vertical'">
        <Button
            v-for="(o, i) in options"
            :key="i"
            :style="{
                minWidth: (100 / options.length) + '%',
                ...getBorderRadiuses(i)
            }"
            :class="[value == o.value ? 'stathub-tab-active': '', 'text-center', 'w-100', 'px-0']"
            :to="o.to"
            @click="value=o.value"
        >
            <Image class="cursor-pointer" v-if="o.img" :src="o.img" size="25px" />
            {{o.title}}
        </Button>
    </div>
</template>

<style lang="scss" scoped>
.stathub-tab-active {
    background: radial-gradient(circle at bottom, #e3ffe150 1%, transparent 70%),
        linear-gradient(#0005, #0005)
}
</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop, Model } from 'vue-property-decorator'
import Button from './Button.vue'
import Image from './Image.vue'

interface Option {
    title: string
    value: any
    img: any
    to: any
}

@Options({components: {Button, Image}})
export default class Tab extends Vue {

    @Prop({default: ''}) readonly name!: string
    @Prop({default: null}) readonly width!: string | number
    @Prop({default: false}) readonly html!: boolean
    @Prop({default: []}) readonly options!: Array<Option>
    @Prop({default: 'horizontal'}) readonly orientation!: string

    @Model('modelValue', {default: null})
    readonly value!: any

    getBorderRadiuses (index: number): any {
        if (index == 0) {
            if (this.orientation == 'horizontal')
                return this.$css.parse({borderRadiuses: ['20px', '0', '0', '20px']})
            else
                return this.$css.parse({borderRadiuses: ['20px', '20px', '0', '0']})
        }
        if (index == this.options.length - 1) {
            if (this.orientation == 'horizontal')
                return this.$css.parse({borderRadiuses: ['0', '20px', '20px', '0']})
            else
                return this.$css.parse({borderRadiuses: ['0', '0', '20px', '20px']})
        }
        return this.$css.parse({borderRadiuses: ['0', '0', '0', '0']})
    }

}
</script>
