<template>
    <input
        class="stathub-input"
        :type="type"
        :placeholder="placeholder"
        :style="{
            width: width,
            background: background,
            ...$css.parse({borderRadiuses})
        }"
        :disabled="disabled"
        v-model="value"
    >
</template>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';

input.stathub-input[type=text] {
    padding: 10px;
    border: 1px solid rgb(88, 220, 238);
    border-radius: 20px;
    font-size: .9rem;
    padding: 8px 16px;
    color: inherit;
}

@include media-breakpoints-down($sm) {
    input.stathub-input[type=text] {
        font-size: .8rem;
        padding: 6px 12px;
    }
}
</style>

<script lang="ts">
import { Prop, Model } from 'vue-property-decorator'
import { Vue } from 'vue-class-component'

export default class Input extends Vue {

    @Prop({default: "text"}) readonly type!: string
    @Prop({default: ""}) readonly placeholder!: string
    @Prop({default: null}) readonly width!: string | number
    @Prop({default: null}) readonly displayImg!: string
    @Prop({default: false}) readonly disabled!: boolean
    @Prop({default: ['20px', '20px', '20px', '20px']}) readonly borderRadiuses!: Array<string>

    @Model('modelValue', { type: String, default: '' })
    readonly value!: string

    get background() : string {
        let str = ''
        if (this.displayImg)
            str += `url(${this.displayImg}) no-repeat center right 16px/30px, `
        str += `linear-gradient(#0005, #0005)`
        return str
    }

}
</script>
