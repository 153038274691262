<template>
    <Nav/>
    <transition name="y-open" mode="out-in">
        <div v-if="updateExists" class="overflow-y-hidden" :style="{marginLeft}">
            <div class="loading-bg py-3 px-4 px-md-5 text-left d-flex align-items-center">
                <Icon name="info-circle" class="block" />
                <div class="ms-3">
                    New version downloaded, please reload the app.
                </div>
                <Button class="d-block py-0 px-2 extra-small ms-3" @click="refreshApp">
                    <span :class="$store.state.winWidth > 572 ? 'extra-small': ''">Reload</span>
                </Button>
            </div>
        </div>
    </transition>
    <transition name="y-open" mode="out-in">
        <div v-if="!policyAccepted" class="overflow-y-hidden" :style="{marginLeft}">
            <div class="cookie-bg py-3 px-4 px-md-5 text-left d-flex align-items-center">
                <Icon name="exclamation-triangle" class="block" />
                <div class="ms-3">
                    By continuing to use this application, you agree with our <router-link to="/privacy" class="text-info" >Privacy Policy</router-link>
                </div>
                <Button class="d-block py-0 px-2 extra-small ms-3" @click="$stge.loc.policyAccepted = policyAccepted = '1'">
                    <span :class="$store.state.winWidth > 572 ? 'extra-small': ''">Dismiss</span>
                </Button>
            </div>
        </div>
    </transition>
    <transition name="y-open" mode="out-in">
        <div class="overflow-y-hidden" :style="{marginLeft}">
            <div class="cookie-bg py-3 px-4 px-md-5 text-left d-flex align-items-center" style="line-height: 1.1">
                <Icon name="exclamation-triangle" class="block" />
                <div class="ms-3">
                    This app has been FROZEN INDEFINITELY since Sep 15 2023 due to lack of demand.
                    Static content updated until Sep 15 2023 will continue to be served.
                    On-demand content such as profiles and matchlist will be disabled.
                </div>
            </div>
        </div>
    </transition>
    <div
        class="router-div"
        :style="{marginLeft}"
    >
        <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
                <Loading v-if="$asset.loading">LOADING</Loading>
                <component v-else :is="Component" :key="$route.fullPath" />
            </transition>
        </router-view>
    </div>
</template>

<style lang="scss">
@import "@/scss/globals.scss";
@import '@/scss/transitions.scss';
@import "@/scss/variables.scss";
@import "@/scss/tooltip.scss";
@import "@/scss/buttons.scss";
@import "@/scss/mixins.scss";

#app {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #ffffff;
    background:
        // linear-gradient(#0e0e0e, #0e0e0e);
        linear-gradient(#02070ede, #02070ede),
        url("https://cdn-stathub.iann838.com/common/static/ionia-castle.jpg") no-repeat center/cover fixed;
        // url("https://cdn-stathub.iann838.com/lol/static/freljord-fanart.jpg") no-repeat center/cover fixed;
        // url("https://cdn-stathub.iann838.com/common/static/magicbg.jpg") no-repeat bottom left/cover fixed;
        // linear-gradient(#000e, #000e);
    // background-color: #0d1920;
    min-height: 100vh;
    padding-top: 68px;
}

@include media-breakpoints-down($md) {
    #app {
        padding-top: 68px;
        padding-bottom: 60px;
    }
}

a {
    text-decoration: none;
    color: inherit;

    &:hover {
        color: inherit;
    }
}

.router-div {
    min-height: calc(100vh - 160px);
    -webkit-transition: margin-left 0.2s ease-in-out;
    -moz-transition: margin-left 0.2s ease-in-out;
    -o-transition: margin-left 0.2s ease-in-out;
    transition: margin-left 0.2s ease-in-out;
}

.whatever {
    color: rgba(0, 251, 255, 0.619);
}

// @include media-breakpoints-down($md) {
//     .router-div {
//         min-height: calc(100vh - 155px);
//     }
// }

@include fade('fade', .06s);
@include y-open(50px);

@include animated-gradient-background(
    'loading-bg',
    'loading-animation-bg',
    (rgba(0, 251, 255, 0.200), rgba(0, 251, 255, 0.100), rgba(0, 251, 255, 0.232), rgba(0, 251, 255, 0.08)),
    2s
);

@include animated-gradient-background(
    'cookie-bg',
    'cookie-animation-bg',
    (rgba(255, 238, 0, 0.200), rgba(255, 238, 0, 0.100), rgba(255, 238, 0, 0.232), rgba(255, 238, 0, 0.08)),
    2s
);

</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import Nav from '@/components/Nav.vue'
import Loading from '@/components/Loading.vue'
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'

interface Route {
    path: string;
}

@Options({components: {Nav, Loading, Icon, Button}})
export default class App extends Vue {

    refreshing = false
    updateExists = false
    registration: any = null
    policyAccepted: any = null
    interfaces: Array<string> = ['', 'lol', 'lor', 'val']
    routeChanged = false

    @Watch('$route')
    routeChange (after: Route, before: Route): void {
        if (!this.routeChanged) {
            const lastInterface = this.$stge.loc.getItem("lastInterface")
            if (lastInterface && this.$route.path == '/')
                this.$router.push('/' + lastInterface)
            this.routeChanged = true
        }
        let afterGame = after.path.split("/")[1]
        let beforeGame = before.path.split("/")[1]
        if (this.interfaces.includes(afterGame) && afterGame != beforeGame) {
            this.$store.commit("setGame", afterGame)
            this.$stge.loc.setItem("lastInterface", afterGame)
            this.$asset.load(afterGame)
        }
    }

    created (): void {
        document.addEventListener(
            'swUpdated', this.showRefreshUI, { once: true }
        )
        if (localStorage.appCached) {
            navigator.serviceWorker.addEventListener(
                'controllerchange', () => {
                    if (this.refreshing) return
                    this.refreshing = true
                    window.location.reload();
                }
            )
        } else {
            localStorage.appCached = "1"
        }
        this.policyAccepted = localStorage.policyAccepted
    }

    mounted(): void {
        this.$store.commit('setWinWidth', window.innerWidth)
        window.addEventListener('resize', () => {
            this.$store.commit('setWinWidth', window.innerWidth)
        })
    }

    get marginLeft(): string {
        if (this.$store.state.winWidth < 768)
            return '0'
        if (this.$store.state.winWidth < 1300)
            return '68px'
        if (this.$store.state.sideNavExpanded)
            return '238px'
        return '68px'
    }

    showRefreshUI (e: Record<string, any>): void {
        this.registration = e.detail
        this.updateExists = true
    }

    refreshApp (): void {
        this.updateExists = false
        if (!this.registration || !this.registration.waiting) { return; }
        this.registration.waiting.postMessage('skipWaiting')
    }

}
</script>
