<template>
    <div class="search-window text-left">
        <div v-if="value || expanded" class="py-3 px-3 px-md-4">
            <Input
                class="d-block d-md-none mx-auto"
                :placeholder="($store.state.game) ? 'Search': 'Enter an interface to enable search feature'"
                width="100%"
                v-model="value"
            ></Input>
            <div v-if="$store.state.game == 'lol'">
                <div class="mt-3">
                    <span style="font-size: 1.2rem" class="text-info ms-1">Player Profiles</span>
                    <div class="mx-3 mt-1">
                        Search for this profile
                        <Button class="ms-3 py-1" :to="`/lol/profiles?name=${value}`" @click="close">Go to Profiles</Button>
                    </div>
                </div>
                <div class="mt-3">
                    <span style="font-size: 1.2rem" class="text-info ms-1">Champions</span>
                    <div class="mx-3 mt-2">
                        <Champion
                            v-for="champ in filterChampions(value)"
                            :key="'search-lol-champs-' + champ[0]"
                            :gkey="champ[0]"
                            :r-size="[32, 36, 40]"
                            class="m-1"
                            :elo="$stge.loc['lol:championSearchElo'] || 'mid'"
                            :link="true"
                            @click="close"
                        />
                    </div>
                    <div class="mx-3" v-if="filterChampions(value).length == 0">
                        No results
                    </div>
                </div>
            </div>
            <div v-else-if="$store.state.game == 'lor'">
                <div class="mt-3">
                    <span style="font-size: 1.2rem" class="text-info ms-1">Player Profiles</span>
                    <div class="mx-3 mt-1">
                        Search for this profile
                        <Button class="ms-3 py-1" :to="`/lor/profiles?name=${value}`" @click="close">Go to Profiles</Button>
                    </div>
                </div>
                <div class="mt-3">
                    <span style="font-size: 1.2rem" class="text-info ms-1">Cards</span>
                    <div class="ms-0 me-1 mt-2 row px-0">
                        <div class="col-12 col-lg-6 col-xxl-4 py-1" v-for="card in filterCards(value)" :key="'search-lor-cards-' + card.cardCode">
                            <Card
                                :code="card.cardCode"
                                height="35px"
                                width="100%"
                                variant="banner"
                                :link="true"
                                @click="close"
                            />
                        </div>
                    </div>
                    <div class="mx-3" v-if="filterCards(value).length == 0">
                        No results
                    </div>
                </div>
            </div>
            <div v-else>
                Please enter an interface to enable search feature.
                <br>
            </div>
            <Button class="py-1 mt-3 ms-auto d-block clear-input-button" @click="value = ''">
                <Icon name="x" class="me-1" /> Clear Search Input
            </Button>
        </div>
    </div>
</template>

<style lang="scss" scoped>

.search-window {
    position: fixed;
    top: 68px;
    left: 0;
    right: 0;
    max-height: calc(100vh - 68px);
    background-color: #001a23;
    box-shadow: 2px 0px 5px #000000;
    z-index: 1;
    overflow-y: auto;
}

@media screen and (max-width: 768px) {
    .search-window {
        width: 100vw;
        max-width: 100vw;
    }
}

.clear-input-button {
    font-size: 12px !important;
}

</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Emit, Model, Prop } from 'vue-property-decorator'
import Champion from '@/components/lol/Champion.vue'
import Card from '@/components/lor/Card.vue'

@Options({components: {Champion, Card}})
export default class Search extends Vue {

    @Model('modelValue') readonly value!: any
    @Prop({default: false}) readonly expanded!: boolean
    @Emit('success') readonly onSuccess!: any

    filterChampions(nameS: string): Array<[string, any]> {
        const filtered: Array<[string, any]> = []
        if (!nameS) {
            return filtered
        }
        const name = nameS.toLowerCase()
        for (const [k, v] of Object.entries(this.$asset.lol.champmainroles)) {
            if (!isNaN(Number(k)))
                continue
            if (k.slice(0, name.length).toLowerCase() == name)
                filtered.push([k, v])
        }
        return filtered
    }

    filterCards(nameS: string): Array<any> {
        const filtered: Array<any> = []
        if (!nameS) {
            return filtered
        }
        const name = nameS.toLowerCase()
        for (const v of Object.values<any>(this.$asset.lor.cards)) {
            if (v.name.slice(0, name.length).toLowerCase() == name)
                filtered.push(v)
        }
        return filtered
    }

    close (): void {
        this.$emit('success')
    }

}
</script>
