<template>
    <div id="nav" class="top-nav d-flex justify-content-start px-3">
        <div class="text-nowrap text-left" style="min-width: 250px">
            <transition name="x-open">
                <div class="my-auto top-nav-button d-inline-block top-nav-icon" v-if="!$route.meta.isRoot" @click="$router.go(-1)">
                    <Icon name="chevron-left" :size="20" />
                </div>
            </transition>
            <div class="top-nav-button d-inline-block top-nav-icon" @click="$store.commit('toggleSideNavExpanded')">
                <Icon name="menu" :size="22" />
            </div>
            <div class="ms-3 d-inline-block justify-content-center cursor-pointer" @click="$router.push('/')">
                <img src="@/assets/logo.png" class="mb-2" style="height: 28px" />
                <!-- <span class="ms-0 d-inline-block" style="font-size: 28px">.GG</span> -->
            </div>
        </div>
        <div class="ms-auto d-flex flex-fill">
            <div class="my-auto flex-fill mx-0 mx-md-5" ref="searchInput">
                <Input
                    class="d-none d-md-block ms-auto"
                    :placeholder="($store.state.game) ? 'Search': 'Enter an interface to enable search feature'"
                    width="100%"
                    v-model="searchInputValue"
                ></Input>
            </div>
            <div class="top-nav-button top-nav-icon ms-0 ms-md-1" v-if="!searchInputValue && !searchOpened" @click="searchOpened = true">
                <Icon name="search" :size="18" />
            </div>
            <div class="top-nav-button top-nav-icon ms-0 ms-md-1" v-else @click="searchInputValue = ''; searchOpened = false">
                <Icon name="x-lg" :size="16" />
            </div>
            <!-- <div class="top-nav-button top-nav-icon ms-0 ms-md-1">
                <Icon name="bell-fill" :size="18" />
            </div> -->
        </div>
    </div>
    <Search v-if="isMounted"
        v-model="searchInputValue"
        :expanded="searchOpened"
        :style="($store.state.winWidth >= 768) ? {
            marginLeft: $refs.searchInput.getBoundingClientRect().left + 'px',
            width: $refs.searchInput.clientWidth + 'px'
        }: {}"
        @success="searchInputValue = ''; searchOpened = false"
    />
    <div :class="['side-nav text-left', !$store.state.sideNavExpanded ? 'collapsed': '']">
        <transition name="sidenav-slide" mode="out-in">
            <div :key="game">
                <div
                    class="d-flex mx-auto cursor-pointer text-nowrap interface-button"
                    v-if="game"
                    :style="getButtonBgByGame(getInterface(game))"
                    :key="`interface-button-${game}`"
                    @click="ingameClicked"
                >
                    <Image :size="28" :src="$cdn.url(game, `static/game-icon.png`)" class="cursor-pointer me-3 d-inline-block my-auto"/>
                    <div class="d-inline-block my-auto" v-if="$store.state.sideNavExpanded">
                        {{getInterface(game).title}}
                    </div>
                </div>
                <div v-else>
                    <router-link
                        v-for="game in availableGames"
                        :key="`interface-button-${game}`"
                        :to="'/' + game.homeUrl"
                        :title="game.title"
                        @click="navButtonClicked"
                    >
                        <div
                            class="d-flex mx-auto cursor-pointer text-nowrap interface-button"
                            :style="getButtonBgByGame(game)"
                        >
                            <Image :size="28" :src="$cdn.url(game.homeUrl, `static/game-icon.png`)" class="cursor-pointer me-3 d-inline-block my-auto"/>
                            <div class="d-inline-block my-auto" v-if="$store.state.sideNavExpanded">
                                {{game.title}}
                            </div>
                        </div>
                    </router-link>
                </div>
                
                <div v-if="game">
                    <router-link :to="popTrailingSlash(`/${game}/${button.url}`)"
                        v-for="button in buttons[game]"
                        :key="`nav-button-${game}-${button.name}`"
                        class="nav-button d-flex mx-auto text-nowrap"
                        @click="navButtonClicked"
                    >
                        <Image :src="require(`@/assets/${button.name.toLowerCase()}_64x64.jpg`)" class="me-3 cursor-pointer d-inline-block my-auto" :size="24" />
                        <span class="d-inline-block my-auto" v-if="$store.state.sideNavExpanded">{{button.name}}</span>
                    </router-link>
                </div>
            </div>
        </transition>
        <hr>
        <div v-if="$store.state.sideNavExpanded">
            <div>
                <small class="text-muted px-4">Desktop & Mobile Apps</small>
                <div class="mt-3"></div>
                <button class="stathub-button-primary mx-4 my-auto fw-bold" v-if="deferredPrompt" @click="installPWA">
                    <img src="/img/icons/PWA_logo.svg" style="height: 12px" alt="">
                    <span class="ms-3" style="font-size: 12px">INSTALL APP</span>
                </button>
            </div>
            <hr>
            <div class="footer py-2 px-4 w-100 text-secondary">
                <div class="small my-1 text-decoration-underline">
                    <router-link @click="navButtonClicked" to="/terms">Terms</router-link>
                </div>
                <div class="small my-1 text-decoration-underline">
                    <router-link @click="navButtonClicked" to="/privacy">Privacy</router-link>
                </div>
                <div class="small my-1 text-decoration-underline">
                    <router-link @click="navButtonClicked" to="/disclaimer">Disclaimer</router-link>
                </div>
                <div class="small my-1 text-decoration-underline">
                    <router-link @click="navButtonClicked" to="/contact">Contact</router-link>
                </div>
                <div class="small my-1 text-decoration-underline">
                    <router-link @click="navButtonClicked" to="/about">About</router-link>
                </div>
            </div>
            <hr>
            <div class="small border-secondary px-4 ms-auto">2021 © StatHub GG</div>
            <br>
        </div>
        <div v-else>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@/scss/transitions.scss';

#nav {
    box-shadow: 0px 2px 5px #000000;
    background-color: #001a23;
    // background: linear-gradient(to right, #02161ddd 20%, #000000bb 40%, #00000092 60%, #00000032 80%, #00000000 100%);
}

.top-nav {
    position: fixed;
    height: 68px;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 2;
}

.side-nav {
    width: 238px;
    position: fixed;
    box-shadow: 2px 0px 5px #000000;
    background-color: #001a23;
    height: calc(100vh - 68px);
    top: 68px;
    left: 0px;
    -webkit-transition: width 0.2s ease-in-out;
    -moz-transition: width 0.2s ease-in-out;
    -o-transition: width 0.2s ease-in-out;
    transition: width 0.2s ease-in-out;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;

    &.collapsed {
        width: 68px;
    }

}

@media screen and (max-width: 768px) {
    
    .side-nav {
        width: 100vw;
        position: fixed;
        box-shadow: 2px 0px 5px #000000;
        background-color: #001a23;
        height: calc(100vh - 68px);
        top: 68px;
        left: 0px;
        -webkit-transition: width 0.2s ease-in-out;
        -moz-transition: width 0.2s ease-in-out;
        -o-transition: width 0.2s ease-in-out;
        transition: width 0.2s ease-in-out;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 1;

        &.collapsed {
            width: 0px;
        }

    }
}

.interface-button {
    cursor: pointer;
    font-weight: 600;
    padding: 25px 20px;
    font-size: 15px;
    
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.nav-button {
    color: #20dfd2;
    cursor: pointer;
    font-weight: 600;
    padding: 20px 22px;
    font-size: 15px;
    
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
        color: #7bfff6;
        background: radial-gradient(circle at right, #e3ffe132 1%, transparent 90%),
        // radial-gradient(ellipse at bottom, #ffffff9e, transparent);
    }

}

.nav-button.router-link-active {
    color: #7bfff6;
    background: radial-gradient(circle at right, #e3ffe132 1%, transparent 90%),
    // radial-gradient(ellipse at bottom, #ffffff9e, transparent);
}

.top-nav-button {
    color: #20dfd2;
    cursor: pointer;
    font-weight: 600;
    padding: 20px 22px;
    font-size: 15px;
    
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
        color: #7bfff6;
        background: radial-gradient(circle at bottom, #e3ffe132 1%, transparent 90%),
        // radial-gradient(ellipse at bottom, #ffffff9e, transparent);
    }
}

.top-nav-icon {
    padding: 22px 10px 23px 10px !important;
    
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.top-nav-button.router-link-active {
    color: #7bfff6;
    background: radial-gradient(circle at bottom, #e3ffe132 1%, transparent 90%),
    // radial-gradient(ellipse at bottom, #ffffff9e, transparent);
}

@include x-slide('sidenav-slide', 200px, -200px, 1, 1, .1s);
@include x-open(50px, 0.1s)

</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import Search from './Search.vue'

interface NavButton {
    name: string;
    url: string;
}

interface Game {
    title: string;
    iconUrl: string;
    colorOverlay: string;
    homeUrl: string;
    active: boolean;
}

@Options({components: {Search}})
export default class Nav extends Vue {

    deferredPrompt: any = null
    isMounted = false
    searchInputValue = ""
    searchOpened = false

    get game () : string {
        return this.$store.state.game
    }
    get winWidth () : number {
        return this.$store.state.winWidth
    }

    buttons: Record<string, Array<NavButton>> = {
        lol: [
            {name: "Home", url: ""},
            {name: "Champions", url: "champions"},
            {name: "Leaderboards", url: "leaderboards/" + localStorage['lol:leaderboardPlatform']},
        ],
        lor: [
            {name: "Home", url: ""},
            {name: "Cards", url: "cards"},
            {name: "Leaderboards", url: "leaderboards/" + localStorage['lor:leaderboardRegion']},
        ]
    }

    availableGames: Array<Game> = [
        {
            title: "League of Legends",
            iconUrl: "https://cdn-stathub.iann838.com/lol/static/home-square.png",
            colorOverlay: "#103b78",
            homeUrl: "lol",
            active: true,
        },
        {
            title: "Legends of Runeterra",
            iconUrl: "https://cdn-stathub.iann838.com/lor/static/yas-square.jpg",
            colorOverlay: "#107868",
            homeUrl: "lor",
            active: true,
        },
    ]

    @Watch('$store.state.winWidth')
    winWidthChanged(after: number, before: number): void {
        if (window.innerWidth < 1300 && this.$store.state.sideNavExpanded && after < before) {
            this.$store.commit('setSideNavExpanded', false)
        } else if (window.innerWidth > 1300 && !this.$store.state.sideNavExpanded && after > before)
            this.$store.commit('setSideNavExpanded', true)
    }

    created (): void {
        window.addEventListener("beforeinstallprompt", e => {
            e.preventDefault()
            this.deferredPrompt = e
        })
        window.addEventListener("appinstalled", () => {
            this.deferredPrompt = null
        })
    }

    mounted (): void {
        if (window.innerWidth < 1300) {
            this.$store.commit('setSideNavExpanded', false)
        }
        this.isMounted = true
    }

    getInterface(game: string): Game | null {
        for (let gameO of this.availableGames)
            if (gameO.homeUrl == game) return gameO
        return null
    }

    getButtonBgByGame(game: Game): string {
        return `
            background: linear-gradient(
                to left,
                ${game.colorOverlay}dd 20%,
                ${game.colorOverlay}bb 40%,
                ${game.colorOverlay}92 60%,
                ${game.colorOverlay}32 80%,
                ${game.colorOverlay}00 100%
            );
        `
    }

    popTrailingSlash (str: string): string {
        if (str[str.length - 1] == '/')
            return str.slice(0, str.length - 1)
        return str
    }

    navButtonClicked (): void {
        if (this.$store.state.winWidth < 1300)
            this.$store.commit('setSideNavExpanded', false)
    }

    ingameClicked (): void {
        this.$router.push('/')
        this.navButtonClicked()
    }

    async installPWA(): Promise<void> {
        this.deferredPrompt.prompt();
    }

}
</script>
