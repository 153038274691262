import { App } from 'vue'


class UuidUtil {

    install (app: App) : void {
        app.config.globalProperties.$uuid = this
    }

    uuid1 (): string {
        return window.performance.now().toString()
    }

}


function createUuid (): UuidUtil {
    return new UuidUtil()
}


declare module '@vue/runtime-core' {

    interface ComponentCustomProperties {
        $uuid: UuidUtil
    }
}

export { createUuid, UuidUtil }
