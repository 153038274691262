
import { Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'
import Icon from './Icon.vue'

@Options({components: {Icon}})
export default class Loading extends Vue {

    @Prop({default: false}) inline!: boolean

}
