
import { Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'

declare global {
    interface Window {
        __vm_add: any
        __vm_remove: any
    }
}

const placementIds: Record<number, Record<number, string>> = {
    160: {
        600: "6113ae6d916ac9068e6603fd",
    },
    300: {
        100: "6113b046916ac9068e660403",
        250: "610aa228d7cbaa08e8d34ee1",
        600: "6113ae57916ac9068e6603fb",
    },
    500: {
        100: "6113b046916ac9068e660403",
        250: "610aa228d7cbaa08e8d34ee1",
        600: "6113ae57916ac9068e6603fb",
    },
    600: {
        100: "6113b046916ac9068e660403",
        250: "610aa228d7cbaa08e8d34ee1",
        600: "6113ae57916ac9068e6603fb",
    },
    728: {
        90: "610aa24133ceb21a9bbabf6d",
    },
    970: {
        90: "6113ae97916ac9068e6603ff",
        250: "6113aea5916ac9068e660401",
    }
}


@Options({})
export default class VenatusAd extends Vue {

    selfPlacementId = ""

    @Prop({default: null}) readonly placementId!: string
    @Prop({default: 100}) readonly height!: number

    created (): void {
        if (this.placementId)
            this.selfPlacementId = this.placementId
    }

    mounted (): void {
        if (!this.selfPlacementId) {
            let width = (this.$refs.adPlacement as HTMLElement).clientWidth
            let byHeight = this.getLowestDiff<Record<number, string>>(width, placementIds)
            if (byHeight == null)
                return
            this.selfPlacementId = this.getLowestDiff<string>(this.height, byHeight) || ""
        }
        if (window.location.host.includes("127.0.0.1"))
            return
        this.$nextTick(() => {
            (window.top).__vm_add = (window.top).__vm_add || [];
            (window.top).__vm_add.push(this.$refs.adPlacement);
        })
    }

    beforeUnmount (): void {
        (window.top).__vm_remove = (window.top).__vm_remove || [];
        (window.top).__vm_remove.push(this.$refs.adPlacement);
    }

    getLowestDiff <T> (value: number, record: Record<number, T>): T | null {
        let lowestDiff = Infinity
        let lowestDiffContent = null
        for (const [checkValue, content] of Object.entries(record)) {
            let diff = Math.abs(value - parseInt(checkValue))
            if (Math.abs(value - parseInt(checkValue)) < lowestDiff) {
                lowestDiff = diff
                lowestDiffContent = content
            }
        }
        if (lowestDiff >= value)
            return null
        return lowestDiffContent
    }

}
