
import { Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class Loading extends Vue {

    @Prop({default: false}) inline!: boolean

}
