import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { cdn, assets, api, stge } from './services'
import { itertool, uuid, css, math, dom, moment, meta, generic } from './utils'
import { VueClipboard } from '@soerenmartius/vue3-clipboard'
import VTooltip from 'v-tooltip'
import ScriptX from 'vue-scriptx'
import VueGtag from "vue-gtag-next"

import VenatusAd from './components/ads/VenatusAd.vue'
import HorizontalBar from './components/charts/HorizontalBar.vue'
import Line from './components/charts/Line.vue'
import Loading from './components/Loading.vue'
import Button from './components/Button.vue'
import Error from './components/Error.vue'
import Icon from './components/Icon.vue'
import Image from './components/Image.vue'
import Input from './components/Input.vue'
import Select from './components/Select.vue'
import Slide from './components/Slide.vue'
import Tab from './components/Tab.vue'
import Tier from './components/Tier.vue'
import Tip from './components/Tip.vue'

import 'v-tooltip/dist/v-tooltip.css'

const vTooltipStyle = {
    themes: {
        tooltip: {
            triggers: ['hover', 'focus', 'touch', 'click'],
        }
    },
    disposeTimeout: 0,
}

createApp(App)

.use(store)
.use(router)

.use(api)
.use(cdn)
.use(stge)
.use(assets)

.use(itertool)
.use(uuid)
.use(css)
.use(math)
.use(dom)
.use(moment)
.use(meta)
.use(generic)

.use(VTooltip, vTooltipStyle)
.use(VueClipboard)
.use(ScriptX)

.use(VueGtag, {
    property: {
        id: "G-WMGB5L895V"
    }
})

.component('HorizontalBar', HorizontalBar)
.component('Line', Line)
.component('VenatusAd', VenatusAd)
.component('Loading', Loading)
.component('Button', Button)
.component('Error', Error)
.component('Icon', Icon)
.component('Image', Image)
.component('Input', Input)
.component('Select', Select)
.component('Slide', Slide)
.component('Tab', Tab)
.component('Tier', Tier)
.component('Tip', Tip)

.mount('#app')
