<template>
    <div :class="[(inline) ? '': 'loading-div', 'd-flex', 'flex-column']">
        <div class="lds-ripple mt-auto mx-auto">
            <div></div>
            <div></div>
        </div>
        <div class="mx-auto mt-3 mx-5">
            <slot></slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.loading-div {
    min-height: 45vh
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
        top: 50px;
        left: 50px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 100px;
        height: 100px;
        opacity: 0;
    }
}
</style>

<script lang="ts">
import { Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class Loading extends Vue {

    @Prop({default: false}) inline!: boolean

}
</script>
