<template>
    <div class="d-inline-block pos-absolute">
        <button
            class="stathub-select d-flex align-items-center"
            :style="{width: width, ...$css.parse({borderRadiuses: IborderRadiuses})}"
            @click="toggle"
        >
            <div class="mx-auto font-size-modify">
                {{selectedTitle}}
            </div>
            <div class="dropdown-toggle ms-auto"></div>
        </button>
        <transition name="y-open">
            <ul class="stathub-select-dropdown"
                v-if="dropdownToggle"
                :style="{width: width, zIndex, ...$css.parse({borderRadiuses: [0, 0, '20px', '20px']})}"
            >
                <li v-for="o in options"
                    :key="o.title"
                    @click="value = o.value; selectedTitle = o.title; toggle()"
                >
                    {{o.title}}
                </li>
            </ul>
        </transition>
    </div>
</template>

<style lang="scss" scoped>
@import "@/scss/mixins.scss";
@import "@/scss/variables.scss";
@import "@/scss/transitions.scss";

@include y-open();

.pos-relative {
    position: relative;
}

.stathub-select {
    @include base-button();
    background: #0005;
    border: 1px solid rgb(88, 220, 238);
    font-size: .9rem;
    border-radius: 20px;
    padding: 8px 12px 8px 12px;

    &:hover {
        background: radial-gradient(circle at bottom, #e3ffe150 1%, transparent 70%),
            linear-gradient(#0005, #0005);
        // radial-gradient(ellipse at bottom, #ffffff9e, transparent);
    }

    & > .dropdown-menu {
        border: 1px solid #fff;
    }
}

.stathub-select-dropdown {
    position: absolute;
    background: #000c;
    list-style: none;
    list-style-type: none;
    padding: 8px 16px;
    border: 1px solid #fff;
    border-top: none;
    overflow: hidden;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: auto;

    & > li:hover {
        cursor: pointer;
        background: radial-gradient(circle at center, #e3ffe150 10%, transparent 90%),
            linear-gradient(#0005, #0005);
    }
}

@include media-breakpoints-down($sm) {
    .stathub-select, .stathub-select-dropdown {
        font-size: .8rem;
        padding: 6px 12px;
    }
}
</style>

<script lang="ts">
import { Emit, Model, Prop, Watch } from 'vue-property-decorator'
import { Vue } from 'vue-class-component'

interface Option {
    title: string
    value: any
}

export default class Select extends Vue {

    selectedTitle: string | null = ""
    dropdownToggle = false
    oldRadiuses!: Array<string>
    readyRadiuses!: Array<string>
    IborderRadiuses!: Array<string>

    @Prop({default: ''}) readonly name!: string
    @Prop({default: null}) readonly width!: string | number
    @Prop({default: []}) readonly options!: Array<Option>
    @Prop({default: 1}) readonly zIndex!: string | number
    @Prop({default: ['20px', '20px', '20px', '20px']}) readonly borderRadiuses!: Array<string>

    @Model('modelValue', {default: null})
    readonly value!: any

    @Emit("change")
    valueChangedEmit(): number {
        return this.value
    }

    @Watch("value")
    valueChanged(): void {
        this.$emit("change")
    }

    created (): void {
        this.IborderRadiuses = [...this.borderRadiuses]
        this.oldRadiuses = ['0', '0']
        this.readyRadiuses = ['0', '0']
        for (const o of this.options)
            if (o.value == this.value)
                this.selectedTitle = o.title
    }

    toggle (): void {
        this.dropdownToggle = !this.dropdownToggle
        this.readyRadiuses[0] = this.oldRadiuses[0]
        this.readyRadiuses[1] = this.oldRadiuses[1]
        this.oldRadiuses[0] = this.IborderRadiuses[2]
        this.oldRadiuses[1] = this.IborderRadiuses[3]
        this.IborderRadiuses[2] = this.readyRadiuses[0]
        this.IborderRadiuses[3] = this.readyRadiuses[1]
        setTimeout(() => {
            this.$forceUpdate();
        }, (this.IborderRadiuses[2] + this.IborderRadiuses[3] != '00') ? 200: 0);
    }

}
</script>
