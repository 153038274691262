
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import Search from './Search.vue'

interface NavButton {
    name: string;
    url: string;
}

interface Game {
    title: string;
    iconUrl: string;
    colorOverlay: string;
    homeUrl: string;
    active: boolean;
}

@Options({components: {Search}})
export default class Nav extends Vue {

    deferredPrompt: any = null
    isMounted = false
    searchInputValue = ""
    searchOpened = false

    get game () : string {
        return this.$store.state.game
    }
    get winWidth () : number {
        return this.$store.state.winWidth
    }

    buttons: Record<string, Array<NavButton>> = {
        lol: [
            {name: "Home", url: ""},
            {name: "Champions", url: "champions"},
            {name: "Leaderboards", url: "leaderboards/" + localStorage['lol:leaderboardPlatform']},
        ],
        lor: [
            {name: "Home", url: ""},
            {name: "Cards", url: "cards"},
            {name: "Leaderboards", url: "leaderboards/" + localStorage['lor:leaderboardRegion']},
        ]
    }

    availableGames: Array<Game> = [
        {
            title: "League of Legends",
            iconUrl: "https://cdn-stathub.iann838.com/lol/static/home-square.png",
            colorOverlay: "#103b78",
            homeUrl: "lol",
            active: true,
        },
        {
            title: "Legends of Runeterra",
            iconUrl: "https://cdn-stathub.iann838.com/lor/static/yas-square.jpg",
            colorOverlay: "#107868",
            homeUrl: "lor",
            active: true,
        },
    ]

    @Watch('$store.state.winWidth')
    winWidthChanged(after: number, before: number): void {
        if (window.innerWidth < 1300 && this.$store.state.sideNavExpanded && after < before) {
            this.$store.commit('setSideNavExpanded', false)
        } else if (window.innerWidth > 1300 && !this.$store.state.sideNavExpanded && after > before)
            this.$store.commit('setSideNavExpanded', true)
    }

    created (): void {
        window.addEventListener("beforeinstallprompt", e => {
            e.preventDefault()
            this.deferredPrompt = e
        })
        window.addEventListener("appinstalled", () => {
            this.deferredPrompt = null
        })
    }

    mounted (): void {
        if (window.innerWidth < 1300) {
            this.$store.commit('setSideNavExpanded', false)
        }
        this.isMounted = true
    }

    getInterface(game: string): Game | null {
        for (let gameO of this.availableGames)
            if (gameO.homeUrl == game) return gameO
        return null
    }

    getButtonBgByGame(game: Game): string {
        return `
            background: linear-gradient(
                to left,
                ${game.colorOverlay}dd 20%,
                ${game.colorOverlay}bb 40%,
                ${game.colorOverlay}92 60%,
                ${game.colorOverlay}32 80%,
                ${game.colorOverlay}00 100%
            );
        `
    }

    popTrailingSlash (str: string): string {
        if (str[str.length - 1] == '/')
            return str.slice(0, str.length - 1)
        return str
    }

    navButtonClicked (): void {
        if (this.$store.state.winWidth < 1300)
            this.$store.commit('setSideNavExpanded', false)
    }

    ingameClicked (): void {
        this.$router.push('/')
        this.navButtonClicked()
    }

    async installPWA(): Promise<void> {
        this.deferredPrompt.prompt();
    }

}
