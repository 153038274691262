
import { Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class CardDescription extends Vue {

    @Prop() readonly card!: any
    @Prop({default: 'Unit'}) readonly type!: string

}
