
import { Prop, Model } from 'vue-property-decorator'
import { Vue } from 'vue-class-component'

export default class Input extends Vue {

    @Prop({default: "text"}) readonly type!: string
    @Prop({default: ""}) readonly placeholder!: string
    @Prop({default: null}) readonly width!: string | number
    @Prop({default: null}) readonly displayImg!: string
    @Prop({default: false}) readonly disabled!: boolean
    @Prop({default: ['20px', '20px', '20px', '20px']}) readonly borderRadiuses!: Array<string>

    @Model('modelValue', { type: String, default: '' })
    readonly value!: string

    get background() : string {
        let str = ''
        if (this.displayImg)
            str += `url(${this.displayImg}) no-repeat center right 16px/30px, `
        str += `linear-gradient(#0005, #0005)`
        return str
    }

}
