import axios from 'axios'
import { App } from 'vue'
import { reactive } from '@vue/reactivity'
import { itertool } from '@/utils'

interface Option {
    title: string
    value: string
}

class AssetStore {

    install (app: App) : void {
        app.config.globalProperties.$asset = reactive(this)
    }

    readonly baseUrl = 'https://cdn-stathub.iann838.com'
    readonly requirements: Record<string, Array<string>> = {
        lol: ["champkeys.json", "champmainroles.json", "items.json", "runes.json", "spells.json"],
        lor: ["cards.json", "cardkeys.json"],
    }

    loading = false
    currentInterface: string | null = null

    // initial data
    data: Record<string, any> = {
        lol: {
            elos: [
                {title: 'High', value: 'high'},
                {title: 'Mid', value: 'mid'},
                {title: 'Low', value: 'low'},
            ],
            longElos: [
                {title: 'High ELO', value: 'high'},
                {title: 'Mid ELO', value: 'mid'},
                {title: 'Low ELO', value: 'low'},
            ],
            roles: [
                {title: 'Top', value: 'top'},
                {title: 'Jg', value: 'jungle'},
                {title: 'Mid', value: 'middle'},
                {title: 'Bot', value: 'bottom'},
                {title: 'Sup', value: 'support'},
            ],
            longRoles: [
                {title: 'Toplane', value: 'top'},
                {title: 'Jungle', value: 'jungle'},
                {title: 'Midlane', value: 'middle'},
                {title: 'Botlane', value: 'bottom'},
                {title: 'Support', value: 'support'},
            ],
            positionsToRoles: {
                "TOP": "top",
                "JUNGLE": "jungle",
                "MIDDLE": "middle",
                "BOTTOM": "bottom",
                "UTILITY": "support",
            },
            roleTitles: {
                top: "Toplane",
                jungle: "Jungle",
                middle: "Midlane",
                bottom: "Botlane",
                support: "Support",
            },
            platforms: [
                {title: "NA", value: "na1"},
                {title: "KR", value: "kr"},
                {title: "EUW", value: "euw1"},
                {title: "EUNE", value: "eun1"},
                {title: "BR", value: "br1"},
                {title: "JP", value: "jp1"},
                {title: "LAN", value: "la1"},
                {title: "LAS", value: "la2"},
                {title: "OCE", value: "oc1"},
                {title: "RU", value: "ru"},
                {title: "TR", value: "tr1"},
            ],
            fullPlatforms: [
                {title: "North America", value: "na1"},
                {title: "Korea", value: "kr"},
                {title: "Europe West", value: "euw1"},
                {title: "Europe Nordic & East", value: "eun1"},
                {title: "Brazil", value: "br1"},
                {title: "Japan", value: "jp1"},
                {title: "Latin North America", value: "la1"},
                {title: "Latin South America", value: "la2"},
                {title: "Oceania", value: "oc1"},
                {title: "Russia", value: "ru"},
                {title: "Turkey", value: "tr1"},
            ],
            tierStyles: [
                {string: 'OP', bgColor: '#dbdb00'},
                {string: 'S', bgColor: '#0ea8eb'},
                {string: 'A', bgColor: '#0be69d'},
                {string: 'B', bgColor: '#db5400'},
                {string: 'C', bgColor: '#6b1e59'},
                {string: 'D', bgColor: '#2e2e2e'},
                {string: 'E', bgColor: '#2e2e2e'},
            ]
        },
        lor: {
            regions: [
                {title: "AM", value: "americas"},
                {title: "EU", value: "europe"},
                {title: "AS", value: "sea"},
            ],
            fullRegions: [
                {title: "Americas", value: "americas"},
                {title: "Europe", value: "europe"},
                {title: "Asia", value: "sea"},
            ],
            factions: [

            ],
            pogTagByRegion: {
                americas: 'pogmurica',
                europe: 'pogeurope',
                sea: 'pogapac',
            }
        },
    }

    callbacks: Record<string, CallableFunction> = {
        lol: () => {
            for (const [k, v] of Object.entries(this.lol.champkeys)) {
                this.lol.champkeys[k.toLowerCase()] = v
            }
        },
        lor: () => {
            for (const [k, v] of Object.entries(this.lor.cardkeys)) {
                this.lor.cardkeys[k.toLowerCase()] = v
            }
        } 
    }

    get lol(): any {
        return this.data['lol']
    }

    get lor(): any {
        return this.data['lor']
    }

    async load (game: string): Promise<void> {
        if (!game) return
        console.log("Changed interface to: " + game)
        this.loading = true
        const tasks = []
        for (const assetName of this.requirements[game]) {
            tasks.push(axios.get(`${this.baseUrl}/${game}/assets/${assetName}`))
        }
        const responses = await Promise.all(tasks)
        for (const [assetName, res] of itertool.zip(this.requirements[game], responses)) {
            this.data[game][assetName.split('.')[0]] = res.data
        }
        this.callbacks[game]()
        this.loading = false
        this.currentInterface = game
    }

    asRecord (options: Array<Option>): Record<string, string> {
        const o: Record<string, string> = {}
        for (const i of options) {
            o[i.value] = i.title
        }
        return o
    }

}


function createAssetStore (): AssetStore {
    return new AssetStore()
}


declare module '@vue/runtime-core' {

    interface ComponentCustomProperties {
        $asset: AssetStore
    }
}

export { createAssetStore, AssetStore, Option }
