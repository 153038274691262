
import { Options, Vue } from 'vue-class-component'
import { Prop, Model } from 'vue-property-decorator'
import Button from './Button.vue'
import Image from './Image.vue'

interface Option {
    title: string
    value: any
    img: any
    to: any
}

@Options({components: {Button, Image}})
export default class Tab extends Vue {

    @Prop({default: ''}) readonly name!: string
    @Prop({default: null}) readonly width!: string | number
    @Prop({default: false}) readonly html!: boolean
    @Prop({default: []}) readonly options!: Array<Option>
    @Prop({default: 'horizontal'}) readonly orientation!: string

    @Model('modelValue', {default: null})
    readonly value!: any

    getBorderRadiuses (index: number): any {
        if (index == 0) {
            if (this.orientation == 'horizontal')
                return this.$css.parse({borderRadiuses: ['20px', '0', '0', '20px']})
            else
                return this.$css.parse({borderRadiuses: ['20px', '20px', '0', '0']})
        }
        if (index == this.options.length - 1) {
            if (this.orientation == 'horizontal')
                return this.$css.parse({borderRadiuses: ['0', '20px', '20px', '0']})
            else
                return this.$css.parse({borderRadiuses: ['0', '0', '20px', '20px']})
        }
        return this.$css.parse({borderRadiuses: ['0', '0', '0', '0']})
    }

}
