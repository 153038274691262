import { App } from 'vue'


class MathUtil {

    install (app: App) : void {
        app.config.globalProperties.$math = this
    }

    round (num: number, factor: number): number {
        return Math.round(num * factor) / factor;
    }

}


function createMath (): MathUtil {
    return new MathUtil()
}


declare module '@vue/runtime-core' {

    interface ComponentCustomProperties {
        $math: MathUtil
    }
}

export { createMath, MathUtil }
