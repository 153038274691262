
import { Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'
import Icon from '@/components/Icon.vue'

@Options({components: {Icon}})
export default class Slide extends Vue {

    @Prop(String) readonly backdropWidth!: string
    @Prop(String) readonly contentWidth!: string
    @Prop({default: '#0008'}) readonly backdropBgColor!: string
    @Prop({default: 'none'}) readonly contentBg!: string
    @Prop({default: ''}) readonly contentClasses!: string

    visible = true

    toggle(): void {
        this.visible = !this.visible
    }

}
