import { App } from 'vue'
import jQ from 'jquery'


class DomUtil {

    install (app: App) : void {
        app.config.globalProperties.$dom = this
    }

    hidePopperOnScroll(el: string): void {
        jQ(el).scroll(() => jQ(".v-popper__popper").remove())
    }

}

function createDom (): DomUtil {
    return new DomUtil()
}


declare module '@vue/runtime-core' {

    interface ComponentCustomProperties {
        $dom: DomUtil
    }
}

export { createDom, DomUtil }
