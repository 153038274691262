<template>
    <div class="stathub-card-description">
        <span class="text-warning bolder">{{card.name}}</span>
        <br><br>
        <span>{{card.descriptionRaw}}</span>
        <div v-if="card.levelupDescriptionRaw">
            <br>
            <span>Level Up: {{card.levelupDescriptionRaw.trim()}}</span>
        </div>
        <br v-else><br>
        <span class="text-success">Health: <span class="bolder">{{card.health}}</span> | Attack: <span class="bolder">{{card.attack}}</span></span>
        <br>
        <span class="text-info">Cost: <span class="bolder">{{card.cost}}</span> | Type: <span class="bolder">{{type}}</span></span>
    </div>
</template>

<style lang="scss" scoped>
.stathub-card-description {
    white-space: pre-wrap;
    max-width: min(100vw, 500px);
    font-size: 13px;
    background: radial-gradient(#000c, #0000);;
}

.bolder {
    font-weight: 700;
}

.text-success {
    color: rgb(44, 204, 135) !important;
}

</style>

<script lang="ts">
import { Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class CardDescription extends Vue {

    @Prop() readonly card!: any
    @Prop({default: 'Unit'}) readonly type!: string

}
</script>
