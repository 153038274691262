
import { Options, Vue } from 'vue-class-component'
import Button from '@/components/Button.vue'
import Image from '@/components/Image.vue'
import Tab from '@/components/Tab.vue'

interface Game {
    title: string;
    bgUrl: string;
    colorOverlay: string;
    homeUrl: string;
    message: string;
    active: boolean;
}

@Options({components: {Button, Image, Tab}})
export default class Home extends Vue {

    availableGames: Array<Game> = [
        {
            title: "League of Legends",
            bgUrl: "https://cdn-stathub.iann838.com/lol/static/home-square.jpg",
            colorOverlay: "#103b78",
            homeUrl: "lol",
            message: "Champions tier lists, builds, runes and stats for all elos.",
            active: true,
        },
        {
            title: "Legends of Runeterra",
            bgUrl: "https://cdn-stathub.iann838.com/lor/static/yas-square.jpg",
            colorOverlay: "#107868",
            homeUrl: "lor",
            message: "Match histories, profiles, leaderboards and master decks.",
            active: true,
        },
        {
            title: "VALORANT",
            bgUrl: "https://cdn-stathub.iann838.com/val/static/phoenix-jett-square.jpg",
            colorOverlay: "#78103f",
            homeUrl: "",
            message: "We'll start working on it after migration is done for LoL",
            active: false,
        },
    ]

    panels = [
        {title: 'Interfaces', value: 'interfaces'},
        {title: 'Updates & Infos', value: 'infos'},
    ]

    selectedPanel = "interfaces"

    created (): void {
        this.$meta.use({
            title: 'StatHub.gg | Gaming Stats Analytics',
            og: {
                image: '/img/icons/android-chrome-maskable-192x192.png',
                description: 'StatHub.gg provides player profiles, game data and statistics, crafted with modern data science.'
            }
        })
    }

    getSquareStylesByGame(game: Game): string {
        return `
            background: linear-gradient(${game.colorOverlay}a8, ${game.colorOverlay}a8),
                url("${game.bgUrl}") no-repeat center top/cover;
        `
    }

}
