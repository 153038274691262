<template>
    <div class="d-inline-flex stathub-tier-clip justify-content-center" :style="{
        backgroundColor: tier.bgColor,
        minWidth: size,
        minHeight: size,
        maxHeight: size,
        maxWidth: size,
        color: 'white',
    }">
        <div class="my-auto p-0">
            {{tier.string}}
        </div>
    </div>
</template>

<style lang="scss" scoped>
.stathub-tier-clip {
    clip-path: polygon(100% 0, 100% 75%, 50% 100%, 0 75%, 0 0);
}
</style>

<script lang="ts">
import { Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'

interface TierStyle {
    string: string
    bgColor: string
}

@Options({})
export default class Tier extends Vue {

    @Prop(Number) readonly value!: number
    @Prop({default: "20px"}) readonly size!: string

    get tier (): TierStyle {
        return this.$asset.lol.tierStyles[this.value]
    }

}
</script>
