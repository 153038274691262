import { App } from 'vue'


interface Counter<T> {
    object: T
    amount: number
}


class itertool {

    install (app: App) : void {
        app.config.globalProperties.$itertool = this
    }

    * range (start: number, stop: number, step = 1): Generator<number, void, undefined> {
        if (start > stop)
            throw new RangeError("Value of start must be <= stop")
        while (start < stop) {
            yield start
            start += step
        }
    }
    
    * zip<T1, T2> (a: T1[], b: T2[]): Generator<[T1, T2], void, undefined> {
        if (a.length != b.length)
            throw new RangeError("Both arrays must be the same length")
        for (const i of this.range(0, a.length)) {
            yield [a[i], b[i]]
        }
    }
    
    * filter<T> (iterable: Iterable<T>, key: CallableFunction): Generator<T, void, undefined> {
        for (const i of iterable) {
            if (key(i))
                yield i
        }
    }

    count<T> (iterable: Iterable<T>, key: T, strict: false): number {
        let counter = 0;
        for (const i of iterable) {
            if (!strict && i == key)
                counter += 1
            else if (strict && i === key)
                counter += 1
        }
        return counter
    }

    counter<T> (iterable: Iterable<T>): Array<Counter<T>> {
        const map: Array<Counter<T>> = [];
        for (const i of iterable) {
            const val = map.find(x => x.object === i)
            if (val == undefined)
                map.push({object: i, amount: 1})
            else
                val.amount += 1
        }
        return map
    }

    * enumerate<T> (iterable: Iterable<T>): Generator<[number, T], void, undefined> {
        let ind = 0
        for (const item of iterable) {
            yield [ind, item]
            ind += 1
        }
    }

}


function createitertool (): itertool {
    return new itertool()
}


declare module '@vue/runtime-core' {

    interface ComponentCustomProperties {
        $itertool: itertool
    }
}

export { createitertool, itertool }
