import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

function appendPrefixGameOnRoutes (game: string, routes: Array<RouteRecordRaw>) {
    for (const route of routes) {
        route.path = "/" + game + route.path
    }
    return routes
}

const lolRoutes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'LolHome',
        component: () => import('../views/lol/Home.vue'),
        meta: { isRoot: true }
    },
    {
        path: '/champions',
        name: 'LolChampions',
        component: () => import('../views/lol/Champions.vue'),
        meta: { isRoot: true }
    },
    {
        path: '/champions/:elo/:key/:role',
        name: 'LolChampion',
        component: () => import('../views/lol/Champion.vue')
    },
    {
        path: '/leaderboards/:platform',
        name: 'LolLeaderboards',
        component: () => import('../views/lol/Leaderboards.vue'),
        meta: { isRoot: true }
    },
    {
        path: '/profiles',
        name: 'LolProfiles',
        redirect: '/lol',
        meta: { isRoot: true }
    },
    {
        path: '/profiles/:platform/:name',
        name: 'LolProfile',
        redirect: '/lol',
    },
]

const lorRoutes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'LorHome',
        component: () => import('../views/lor/Home.vue'),
        meta: { isRoot: true }
    },
    {
        path: '/cards',
        name: 'LorCards',
        component: () => import('../views/lor/Cards.vue'),
        meta: { isRoot: true }
    },
    {
        path: '/cards/:code/:seo?',
        name: 'LorCard',
        component: () => import('../views/lor/Card.vue'),
        meta: { isRoot: false }
    },
    {
        path: '/leaderboards/:region',
        name: 'LorLeaderboards',
        component: () => import('../views/lor/Leaderboards.vue'),
        meta: { isRoot: true }
    },
    {
        path: '/profiles',
        name: 'LorProfiles',
        redirect: '/lor',
        meta: { isRoot: true }
    },
    {
        path: '/profiles/:name/:tag',
        name: 'LorProfile',
        redirect: '/lor',
    },
]

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { isRoot: true }
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('../views/About.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('../views/Contact.vue')
    },
    {
        path: '/disclaimer',
        name: 'Disclaimer',
        component: () => import('../views/Disclaimer.vue')
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: () => import('../views/Privacy.vue')
    },
    {
        path: '/terms',
        name: 'Terms',
        component: () => import('../views/Terms.vue')
    },
    ...appendPrefixGameOnRoutes('lol', lolRoutes),
    ...appendPrefixGameOnRoutes('lor', lorRoutes)
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
