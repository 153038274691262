import { createStore } from 'vuex'
import { Store } from 'vuex'

interface State {
    game: string
    winWidth: number
    sideNavExpanded: boolean
}

declare module '@vue/runtime-core' {

    interface ComponentCustomProperties {
        $store: Store<State>
    }
}

export default createStore({
    state: {
        game: null,
        winWidth: 1200,
        sideNavExpanded: true
    },
    mutations: {
        setGame (state, game) {
            state.game = game
        },
        setWinWidth (state, width) {
            state.winWidth = width
        },
        setSideNavExpanded (state, bool) {
            state.sideNavExpanded = bool
        },
        toggleSideNavExpanded (state) {
            state.sideNavExpanded = !state.sideNavExpanded
        }
    },
    actions: {
    },
    modules: {
    }
})
