<template>
    <div class="pt-3 pb-3">
        <h1 class="text-center">StatHub Network</h1>
        <h5 class="text-center mt-2 sub-title">Game Stats Analytics</h5>
        <div class="d-flex justify-content-center mt-2">
            <div style="width: 30px">
                <!-- <a href="https://discord.com/invite/PJR2CEq" target="_blank" class="text-white">
                    <Image src="https://cdn-stathub.iann838.com/common/static/discord-logo.svg" class="cursor-pointer" :size="30" />
                </a> -->
            </div>
        </div>
        <Tab class="mt-4 mx-3 d-block d-md-none" :options="panels" v-model="selectedPanel"></Tab>
        <div class="row d-flex align-items-stretch mt-2 mt-md-4 pt-2 w-100 mx-0">
            <div class="col-12 col-md-6 col-lg-5 col-xl-4 px-0" v-if="$store.state.winWidth > 768 || selectedPanel == 'interfaces'">
                <div
                    v-for="game in availableGames"
                    :key="`home-square-${game.title}`"
                    class="home-square d-flex flex-column"
                    :style="getSquareStylesByGame(game)"
                >
                    <div class="mt-auto pb-1 px-1 w-100">
                        <span class="d-inline-block py-2 mx-2 fw-bold">{{game.title}}</span>
                        <Button class="mx-2" v-if="game.active" :to="game.homeUrl">Open Interface</Button>
                        <Button class="mx-2" v-else>Coming Soon</Button>
                    </div>
                    <div class="pb-2 px-2 w-100">
                        <span class="d-inline-block py-2 mx-2 mx-lg-3 small" v-if="game.message">{{game.message}}</span>
                        <span class="d-inline-block py-2 mx-2 mx-lg-3 small" v-else>.</span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-7 col-xl-8 px-0 info-section px-2 px-md-5 d-flex flex-column small" v-if="$store.state.winWidth > 768 || selectedPanel == 'infos'">
                <div class="">
                    <h5 class="ms-4 mt-3 text-left">Recent Updates</h5>
                    <ul style="list-style-type: none;" class="text-left p-0">
                        <li class="px-2 py-2 info-li">
                            - This app has been FROZEN INDEFINITELY since Sep 15 2023 due to lack of demand.
                              Static content updated until Sep 15 2023 will continue to be served.
                              On-demand content such as profiles and matchlist will be disabled.
                        </li>
                        <li class="px-2 py-2 info-li">
                            - This app will NO longer be served at <b>stathub.gg</b>, it will instead be served at <b>stathub.iann838.com</b> due to domain and DNS related costs.
                        </li>
                    </ul>
                </div>
                <div class="mt-4">
                    <h5 class="ms-4 mt-3 text-left">Tips and infos</h5>
                    <ul style="list-style-type: none;" class="text-left p-0">
                        <li class="px-2 py-2 info-li-pro">- This app <span class="fw-bold"><i>is installable in all devices as a progressive web app to your home screen !</i></span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";

.sub-title {
    color: #d0d0d0;
}

.home-square {
    min-height: 140px;
}

.info-li {
    background-color: #053951a7;
    text-decoration: none;
    margin: 15px 20px;
}

.info-li-pro {
    background-color: #055051a7;
    text-decoration: none;
    margin: 15px 20px;
}

</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Button from '@/components/Button.vue'
import Image from '@/components/Image.vue'
import Tab from '@/components/Tab.vue'

interface Game {
    title: string;
    bgUrl: string;
    colorOverlay: string;
    homeUrl: string;
    message: string;
    active: boolean;
}

@Options({components: {Button, Image, Tab}})
export default class Home extends Vue {

    availableGames: Array<Game> = [
        {
            title: "League of Legends",
            bgUrl: "https://cdn-stathub.iann838.com/lol/static/home-square.jpg",
            colorOverlay: "#103b78",
            homeUrl: "lol",
            message: "Champions tier lists, builds, runes and stats for all elos.",
            active: true,
        },
        {
            title: "Legends of Runeterra",
            bgUrl: "https://cdn-stathub.iann838.com/lor/static/yas-square.jpg",
            colorOverlay: "#107868",
            homeUrl: "lor",
            message: "Match histories, profiles, leaderboards and master decks.",
            active: true,
        },
        {
            title: "VALORANT",
            bgUrl: "https://cdn-stathub.iann838.com/val/static/phoenix-jett-square.jpg",
            colorOverlay: "#78103f",
            homeUrl: "",
            message: "We'll start working on it after migration is done for LoL",
            active: false,
        },
    ]

    panels = [
        {title: 'Interfaces', value: 'interfaces'},
        {title: 'Updates & Infos', value: 'infos'},
    ]

    selectedPanel = "interfaces"

    created (): void {
        this.$meta.use({
            title: 'StatHub.gg | Gaming Stats Analytics',
            og: {
                image: '/img/icons/android-chrome-maskable-192x192.png',
                description: 'StatHub.gg provides player profiles, game data and statistics, crafted with modern data science.'
            }
        })
    }

    getSquareStylesByGame(game: Game): string {
        return `
            background: linear-gradient(${game.colorOverlay}a8, ${game.colorOverlay}a8),
                url("${game.bgUrl}") no-repeat center top/cover;
        `
    }

}
</script>
