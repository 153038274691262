import { createitertool } from './itertool'
import { createUuid } from './uuid'
import { createMath } from './math'
import { createCss } from './css'
import { createDom } from './dom'
import { createMoment } from './moment'
import { createMeta } from './meta'
import { createGeneric } from './generic'

const itertool = createitertool()
const uuid = createUuid()
const math = createMath()
const css = createCss()
const dom = createDom()
const moment = createMoment()
const meta = createMeta()
const generic = createGeneric()

export { itertool, uuid, math, css, dom, moment, meta, generic }
