import { App } from 'vue'

const _console = console

type CallbackFunction = () => void;

class Generic {

    install (app: App) : void {
        app.config.globalProperties.$generic = this
    }

    get rem(): number {
        return parseFloat(getComputedStyle(document.documentElement).fontSize)
    }

    get console (): Console {
        return _console
    }

    throttle(fn: CallbackFunction, wait: number): CallbackFunction {
        let time = Date.now();
        return function() { 
            if ((time + wait < Date.now())) {
                fn();
                time = Date.now();
            } else {
                setTimeout(() => {
                    if (time + wait > Date.now())
                        fn();
                        time = Date.now();
                }, wait + 10)
            }
        }
    }

    startCase(str: string): string {
        const result = str.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult
    }

}


function createGeneric (): Generic {
    return new Generic()
}


declare module '@vue/runtime-core' {

    interface ComponentCustomProperties {
        $generic: Generic
    }
}

export { createGeneric, Generic }
