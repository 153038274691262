<template>
    <transition name="x-slide">
        <div class="slide d-flex" v-if="visible">
            <div class="flex-fill" :style="{backgroundColor: backdropBgColor}" @click="visible = false; $emit('close')">
            </div>
            <div class="app-mimic" :style="{width: contentWidth}">
                <div
                    @click="visible = false; $emit('close')"
                    class="py-2 px-3 cursor-pointer null-bg mr-auto text-left"
                ><Icon name="chevron-right" /></div>
                <slot></slot>
            </div>
        </div>
        <div v-else></div>
    </transition>
</template>

<style lang="scss" scoped>
@import '@/scss/transitions.scss';

@include x-slide('x-slide', 100vw, 100vw, 0, 0, 0.2s);

.app-mimic {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #ffffff;
    background: linear-gradient(#02070ec9, #02070ec9),
        url("https://cdn-stathub.iann838.com/lol/static/freljord-fanart.jpg") no-repeat center/cover fixed,
        linear-gradient(#000e, #000e);
    min-height: 100vh;
    padding-top: 60px;
}

.slide {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    padding: 0;
}

.mimic-border {
    border-left: 2px solid #7bfff66d
}


.null-bg {
    background: linear-gradient(to right, rgba(31, 225, 255, 0.188) 0%, rgba(31, 225, 255, 0) 100%);
}

</style>

<script lang="ts">
import { Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'
import Icon from '@/components/Icon.vue'

@Options({components: {Icon}})
export default class Slide extends Vue {

    @Prop(String) readonly backdropWidth!: string
    @Prop(String) readonly contentWidth!: string
    @Prop({default: '#0008'}) readonly backdropBgColor!: string
    @Prop({default: 'none'}) readonly contentBg!: string
    @Prop({default: ''}) readonly contentClasses!: string

    visible = true

    toggle(): void {
        this.visible = !this.visible
    }

}
</script>
