
<script>
/* eslint-disable */
import { Line } from "vue3-chart-v2";

export default {
    extends: Line,
    props: ["data", "options"],
    mounted () {
        this.renderChart(this.data, {
            ...this.options,
            animation: {
                duration: 200
            }
        });
    }
};
</script>

<style>
</style>