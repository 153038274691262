import { App } from 'vue'

interface Options {
    borderRadiuses: Array<string>
}

class CssUtil {

    install (app: App) : void {
        app.config.globalProperties.$css = this
    }

    readonly parsers: Record<string, CallableFunction> = {
        borderRadiuses: (o: any, v: Array<string>) => {
            o['border-top-left-radius'] = v[0]
            o['border-top-right-radius'] = v[1]
            o['border-bottom-right-radius'] = v[2]
            o['border-bottom-left-radius'] = v[3]
        },
    }

    parse (options: Options): any {
        const o = {}
        for (const [k, v] of Object.entries(options)) {
            this.parsers[k](o, v)
        }
        return o
    }

    asDimension (val: number, metric = 'px'): string {
        if (!isNaN(val))
            return val.toString() + metric
        return val.toString()
    }

    capFirst (str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1)
    }

}


function createCss (): CssUtil {
    return new CssUtil()
}


declare module '@vue/runtime-core' {

    interface ComponentCustomProperties {
        $css: CssUtil
    }
}

export { createCss, CssUtil }
