
import { Options, Vue } from 'vue-class-component'
import { Emit, Model, Prop } from 'vue-property-decorator'
import Champion from '@/components/lol/Champion.vue'
import Card from '@/components/lor/Card.vue'

@Options({components: {Champion, Card}})
export default class Search extends Vue {

    @Model('modelValue') readonly value!: any
    @Prop({default: false}) readonly expanded!: boolean
    @Emit('success') readonly onSuccess!: any

    filterChampions(nameS: string): Array<[string, any]> {
        const filtered: Array<[string, any]> = []
        if (!nameS) {
            return filtered
        }
        const name = nameS.toLowerCase()
        for (const [k, v] of Object.entries(this.$asset.lol.champmainroles)) {
            if (!isNaN(Number(k)))
                continue
            if (k.slice(0, name.length).toLowerCase() == name)
                filtered.push([k, v])
        }
        return filtered
    }

    filterCards(nameS: string): Array<any> {
        const filtered: Array<any> = []
        if (!nameS) {
            return filtered
        }
        const name = nameS.toLowerCase()
        for (const v of Object.values<any>(this.$asset.lor.cards)) {
            if (v.name.slice(0, name.length).toLowerCase() == name)
                filtered.push(v)
        }
        return filtered
    }

    close (): void {
        this.$emit('success')
    }

}
