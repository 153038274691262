<template>
    <router-link v-if="link" :to="Ilink" :style="{minHeight: Isize, minWidth: Isize, maxHeight: Isize, maxWidth: Isize}">
        <div class="img-container">
            <Image
                :src="$cdn.url('lol', ['champions', Iid], 'png')"
                class="stathub-champion-img cursor-pointer"
                :size="Isize"
            />
        </div>
    </router-link>
    <div v-else class="img-container" :style="{minHeight: Isize, minWidth: Isize, maxHeight: Isize, maxWidth: Isize}">
        <Image
            :src="$cdn.url('lol', ['champions', Iid], 'png')"
            class="stathub-champion-img-static"
            :size="Isize"
        />
    </div>
</template>

<style lang="scss" scoped>
.stathub-champion-img {
    transform: scale(1.1);
    &:hover {
        transform: scale(1.2);
    }
}

.stathub-champion-img-static {
    transform: scale(1.1);
}

div.img-container {
    border-radius: 50%;
    display: inline-block;
    overflow: hidden;
}
</style>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'
import Image from '../Image.vue'


@Options({components: {Image}})
export default class Champion extends Vue {

    Iid: number | string = ""
    Ikey: number | string = ""
    Ielo: number | string = ""
    Irole: number | string = ""
    Isize: number | string = ""
    Ilink = ""

    @Prop({default: null}) readonly gid!: number
    @Prop({default: null}) readonly gkey!: number
    @Prop({default: null}) readonly elo!: number
    @Prop({default: null}) readonly role!: number
    @Prop({default: false}) readonly link!: boolean
    @Prop({default: "100%"}) readonly size!: number
    @Prop({default: []}) readonly rSize!: Array<number>

    get winWidth(): number {
        return this.$store.state.winWidth
    }

    created (): void {
        this.Iid = this.gid || this.$asset.lol.champkeys[this.gkey].id
        this.Ikey = this.gkey || this.$asset.lol.champkeys[this.Iid].key
        this.newLink()
        this.Isize = this.$css.asDimension(this.size)
        this.getSize(this.winWidth)
    }

    @Watch('winWidth')
    getSize(after: number | string): void {
        if (this.rSize.length == 0)
            this.Isize = this.$css.asDimension(this.size)
        else if (after < 576)
            this.Isize = this.$css.asDimension(this.rSize[0])
        else if (after < 992)
            this.Isize = this.$css.asDimension(this.rSize[1])
        else
            this.Isize = this.$css.asDimension(this.rSize[2])
    }

    @Watch('elo')
    @Watch('role')
    newLink(): void {
        if (this.link) {
            this.Ielo = this.elo || this.$stge.loc['lol:championRankingRole'] || "mid"
            this.Irole = this.role || this.$asset.lol.champmainroles[this.Iid][this.Ielo]
            this.Ilink = `/lol/champions/${this.Ielo}/${this.Ikey}/${this.Irole}`
        }
    }

}
</script>
